import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  identifier?: string
  title?: string
  text?: string
}

export const MainParagraphStripe = memo(function MainParagraphStripe({
  identifier,
  title,
  text,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {identifier && identifier === 'Converto' ? (
          <LogoConverto src="/logo-converto.png" width="348" height="52" />
        ) : null}
        {text ? <Text dangerouslySetInnerHTML={{ __html: text }} /> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.variants.primary};
`

const Wrapper = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 5.625rem 2rem;
  text-align: center;
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
`

const LogoConverto = styled.img`
  width: auto;
  height: 2.5rem;
  margin: 1rem auto 0;
`

const Text = styled.div`
  color: #555;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  line-height: 1.5rem;
  margin-top: 3.125rem;
  text-align: left;

  p {
    margin: 1rem 0;
  }
`
