import styled from '@emotion/styled'
import { MainParagraphStripe } from 'app/components/MainParagraphStripe'
import { PartnersLogos } from 'app/components/PartnersLogos'
import { SEO } from 'app/components/SEO'
import {
  CustomerReviews,
  Props as CustomerReviewsProps,
} from 'app/containers/CustomerReviews'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import {
  MainLeadGeneration,
  Props as MainLeadGenerationProps,
} from 'app/containers/MainLeadGeneration'
import { Navigation, Props as NavigationProps } from 'app/containers/Navigation'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  navigationProps?: NavigationProps
  mainLeadGenerationProps?: MainLeadGenerationProps
  customerReviewsProps?: CustomerReviewsProps
  footerProps?: FooterProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function PartnersPageTemplate({
  pageContext,
}: PageProps<void, PageContext>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.navigationProps ? (
        <Navigation
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.navigationProps}
        />
      ) : null}
      {context.mainParagraphStripeProps ? (
        <MainParagraphStripe {...context.mainParagraphStripeProps} />
      ) : null}
      {context.partnersLogosProps ? (
        <PartnersLogos variant="grid" {...context.partnersLogosProps} />
      ) : null}
      {context.customerReviewsProps ? (
        <CustomerReviews
          languageCode={pageContext.languageCode}
          {...context.customerReviewsProps}
        />
      ) : null}
      {context.mainLeadGenerationProps ? (
        <MainLeadGeneration
          languageCode={pageContext.languageCode}
          {...context.mainLeadGenerationProps}
        />
      ) : null}
      {context.footerProps ? (
        <Footer
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
