import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  title?: string
  logos: ImageProps[]
  variant?: Variant
}

export const PartnersLogos = memo(function PartnersLogos({
  title,
  logos,
  variant = 'default',
}: Props) {
  return (
    <Container space="between" tag={variant === 'grid' ? 'section' : undefined}>
      {title ? <Title>{title}</Title> : null}
      <Logos row wrap dial={5} variant={variant}>
        {logos.map((item, index) => (
          <LazyLoadComponent key={index}>
            <Image {...item} />
          </LazyLoadComponent>
        ))}
      </Logos>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  max-width: 1400px;
  background-color: white;
  margin: auto;
  padding: 2rem;
`

const Title = styled.h2`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.variants.primary};
  margin: 1rem 0 2rem 0;
  padding: 0 1rem;
  text-align: center;

  @media all and (max-width: 571px) {
    padding: 0;
    font-size: 2rem;
  }
`

const Logos = styled(FlexBox)<ContainerProps>`
  width: 100%;

  .lazy-load-image-background {
    width: auto !important;
    height: auto !important;
    position: relative;
    top: auto;
    left: auto;
  }

  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 12.5rem;
    max-height: 4.125rem;
    margin: 2.5% 3.75rem;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'grid':
        return css`
          img {
            margin-top: 10%;
            margin-bottom: 10%;
          }
        `
    }
  }}
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'grid'
